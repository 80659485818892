// this file might be a symlink
// magic values will be replaced during docker container runtime

module.exports = {
    NEXT_SHOP_EXTERNAL_URL: 'https://ita.tools',
    NEXT_SHOP_EXTERNAL_DOMAIN: 'ita.tools',
    BACKEND_INTERNAL_URL: 'http://api.ita.tools:8080',
    BACKEND_EXTERNAL_URL: 'https://panel.ita.tools/api',
    TINYMCE_API_KEY: 'qil8aezplun7xg2k5si0o8uuzlb9czbkgw54uyapf5hi1dfl'
}
